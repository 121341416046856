import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import styles from './AgendaCustom.module.css';
import { Box, Button, Typography } from '@mui/material';
import SpeakerAccordion from './SpeakerAccordion';
import { useState } from 'react';
import { downArrow, upArrow } from '../../assets/Images/Images';

const RenderTimeRow = ({ row }) => {
  const { time, title } = row;
  return (
    <TableRow className={styles['time-container']}>
      <TableCell sx={{ p: 2 }}>
        <Typography
          sx={{
            fontSize: { sm: 22, xs: 17, p: 0 },
            fontWeight: 'bold',
            maxWidth: '150px',
            minWidth: '150px',
          }}
        >
          {time}
        </Typography>
      </TableCell>
      <TableCell sx={{ minWidth: '300px' }} tabIndex={-1}></TableCell>
      <TableCell>
        <Typography
          sx={{
            fontSize: { sm: 22, xs: 17 },
            fontWeight: 'bold',
            color: '#FFF',
            pt: { xs: 1, sm: 0 },
            pb: { xs: 1, sm: 0 },
          }}
        >
          {title}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const RenderSessionRow = ({ row }) => {
  const { time, programs } = row;
  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleChange = (panel) => {
    setExpandedPanel((prev) => {
      return prev === panel ? null : panel;
    });
  };

  return (
    <>
      {programs?.map((pgm, pIndex) => {
        const { name: sessionTitle, speakers, description, link, isArrow = true, noBio = false } = pgm;

        return (
          <TableRow className={styles['row-body']}>
            {pIndex === 0 && (
              <TableCell rowSpan={programs.length} sx={{ verticalAlign: 'top' }}>
                <Typography
                  sx={{
                    fontSize: { sm: 22, xs: 17 },
                    fontWeight: 'bold',
                    maxWidth: '150px',
                  }}
                >
                  {time}
                </Typography>
              </TableCell>
            )}
            <TableCell
              sx={{ maxWidth: '400px', minWidth: '400px', backgroundColor: (pIndex + 1) % 2 === 0 ? '#2c303a' : 'transparent' }}
              tabIndex={isArrow === false && '-1'}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', borderRight: isArrow && '1px solid #4d4d4d' }}>
                {speakers.map((speaker) => {
                  const { name, designation, bio } = speaker;
                  return (
                    <>
                      <SpeakerAccordion expanded={expandedPanel} panelId={sessionTitle} name={name} designation={designation} bio={bio} />
                    </>
                  );
                })}
              </Box>
            </TableCell>
            <TableCell sx={{ backgroundColor: (pIndex + 1) % 2 === 0 ? '#2c303a' : 'transparent', verticalAlign: 'baseline' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: (pIndex + 1) % 2 === 0 ? '#2c303a' : 'transparent' }}>
                <Typography
                  sx={{
                    fontSize: { sm: 22, xs: 17 },
                    fontWeight: 'bold',
                    color: '#FFF',
                    pb: { xs: 1, sm: 0 },
                  }}
                >
                  {sessionTitle}
                </Typography>
                {isArrow && !noBio && (
                  <Button
                    onClick={() => handleChange(sessionTitle)}
                    disableRipple
                    sx={{
                      m: 0,
                      ml: 1,
                      alignItems: 'flex-start',
                      '&:focus-visible': {
                        borderRadius: 50,
                        outline: '2px dotted #FFF',
                        outlineOffset: 0,
                      },
                    }}
                  >
                    {expandedPanel === sessionTitle ? (
                      <img width='50%' src={upArrow} alt='click here to collapse the information about speaker and session' />
                    ) : (
                      <img width='50%' src={downArrow} alt='click here to expand and read more about speaker and session' />
                    )}
                  </Button>
                )}
              </Box>
              {expandedPanel === sessionTitle && (
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: 16,
                    color: '#dddddd',
                    width: '92%',
                    pb: { xs: 1, sm: 0 },
                  }}
                >
                  {description}
                  {link && (
                    <Box sx={{ pt: '10px' }}>
                      <a href={link} style={{ color: '#fff', fontWeight: 'bold' }} target='_blank' rel='noreferrer'>
                        Session pre-read
                      </a>
                    </Box>
                  )}
                </Typography>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
const AgendaForDesktop = ({ agenda_rows }) => {
  return (
    <TableContainer className={styles['agenda-table-container']}>
      <Table aria-label='Elevate 2025 agenda' className={styles['agenda-table']}>
        <TableBody>
          {agenda_rows.map((row) => (
            <>{row.speakers === null ? <RenderTimeRow row={row} /> : <RenderSessionRow row={row} />}</>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AgendaForDesktop;
