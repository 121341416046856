import React, { useState } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
// import agenda_rows from './mock.json';
import styles from './AgendaCustom.module.css';
import AgendaForDesktop from './AgendaForDesktop';
import SpeakerAccordion from './SpeakerAccordion';
import { downArrow, upArrow } from '../../assets/Images/Images';

const AgendaTime = (props) => {
  const { time = '', title = '', subtitle = '', isTable = false } = props;
  return (
    <Box sx={{ pb: 1, pt: 1 }}>
      <Box sx={{ display: 'flex' }}>
        <Grid container>
          {time && (
            <Grid item xs={12} sm={12} md={!title ? 12 : 2} className={styles['time-container']}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  p: '5px 16px',
                }}
              >
                {time}
              </Typography>
            </Grid>
          )}
          {title && (
            <Grid item xs={12} sm={12} md={10} sx={{ textAlign: 'left' }} className={isTable && styles['time-container']}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: '24px',
                  pb: { xs: 1, sm: 0 },
                }}
              >
                {title}
              </Typography>
            </Grid>
          )}
          {subtitle && (
            <Grid item xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                }}
              >
                {subtitle}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
const SpeakerInfo = (props) => {
  const {
    name = '',
    designation = '',
    company = '',
    isArrow = true,
    noBio = false,
    bio,
    title: sessionTitle,
    expandedPanel,
    handleChange,
    sid,
  } = props;

  return (
    <Box sx={{ pb: 1, display: 'flex', alignItems: 'baseline', ml: '-16px', justifyContent: 'space-between' }}>
      {isArrow && <SpeakerAccordion expanded={expandedPanel} panelId={sessionTitle} name={name} designation={designation} bio={bio} />}
      {sid === 0 && isArrow && !noBio && (
        <Box>
          <Button
            onClick={() => handleChange(sessionTitle)}
            disableRipple
            sx={{ p: 0, m: 0, maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}
          >
            {expandedPanel === sessionTitle ? (
              <img width='80%' src={upArrow} alt='click here to collapse the information about speaker and session' />
            ) : (
              <img width='80%' src={downArrow} alt='click here to expand and read more about speaker and session' />
            )}
          </Button>
        </Box>
      )}

      {company && <Typography sx={{ fontWeight: '400', fontSize: 17, color: '#FFF' }}>{company}</Typography>}
    </Box>
  );
};
const AgendaRow = (props) => {
  const { programs = [] } = props;
  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleChange = (panel) => {
    setExpandedPanel((prev) => {
      return prev === panel ? null : panel;
    });
  };

  return (
    <Box>
      {programs.map((program, pid) => {
        return (
          <>
            {program.isArrow === undefined && (
              <Grid
                container
                key={pid}
                sx={{
                  p: 2,
                  textAlign: 'left',
                }}
                className={`${program.isArrow} hi`}
              >
                <Grid item xs={12} md={4}>
                  <Box>
                    {program?.speakers?.length > 0 &&
                      program.speakers.map((speaker, sid) => {
                        return (
                          <SpeakerInfo
                            {...speaker}
                            sid={sid}
                            noBio={program.noBio}
                            isArrow={program.isArrow}
                            title={program.name}
                            expandedPanel={expandedPanel}
                            handleChange={handleChange}
                          />
                        );
                      })}
                  </Box>
                </Grid>
                {program.isArrow === undefined && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      borderBottom: (expandedPanel === null || expandedPanel !== program.name) && pid < programs.length - 1 && '0.3px solid #8B7A83',
                      pb: '30px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#fff',
                        fontSize: '20px',
                        fontWeight: 500,
                        ml: '-16px',
                      }}
                    >
                      {!program.isArrow && program?.name}
                    </Typography>
                  </Grid>
                )}
                {expandedPanel === program?.name && (
                  <Grid
                    item
                    sx={{
                      display: 'block',
                      color: '#dddddd',
                      borderBottom: pid < programs.length - 1 && '0.3px solid #8B7A83',
                      pb: program?.description && '30px',
                    }}
                    xs={12}
                    md={4}
                  >
                    {program?.description}
                    {program.link && (
                      <Box sx={{ pt: '10px' }}>
                        <a href={program.link} target='_blank' style={{ color: '#fff', fontWeight: 'bold' }} rel='noreferrer'>
                          Session pre-read
                        </a>
                      </Box>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
          </>
        );
      })}
    </Box>
  );
};

const AgendaCustom = (props) => {
  const { data: agenda_rows = [] } = props;

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <div id='agenda'>
      {mobile ? (
        <Grid container sx={{ p: '25px' }} className={styles['agenda-container']}>
          {/* <Typography variant='h2' sx={{ color: '#FFF', pb: 2, fontSize: '30px', fontWeight: 'bold' }}>
            Agenda
          </Typography> */}
          {agenda_rows.map((row, rowid) => (
            <Box
              key={rowid}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {(row?.time || row?.title) && (
                <AgendaTime time={row?.time} title={!row?.speakers && row?.title ? row?.title : ''} subtitle={row?.subtitle ? row.subtitle : ''} />
              )}
              <Box sx={{ p: 0 }}>{row?.programs && <AgendaRow programs={row?.programs} />}</Box>
            </Box>
          ))}
        </Grid>
      ) : (
        <Grid container sx={{}} className={styles['agenda-container']}>
          {/* <Typography variant='h3' sx={{ color: '#FFF', p: '30px 0 20px 6%', marginTop: '40px', fontSize: '58px', fontWeight: 'bold' }}>
            Agenda
          </Typography> */}
          <AgendaForDesktop agenda_rows={agenda_rows} />
        </Grid>
      )}
    </div>
  );
};

export default AgendaCustom;
