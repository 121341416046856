import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import SignUp from '../Registration/SignUpDialog';
import Login from '../Login/Login';
import { Collapse } from '@mui/material';
// import { NavHashLink } from 'react-router-hash-link';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    transition: 'height 1s ease !important',
    '& .MuiPaper-root': {
      maxWidth: '100%',
      left: '0 !important',
      filter: 'none !important',
      transition: 'height 1s ease !important',
      top: '40px !important',
    },
    '& .MuiMenuItem-root': {
      '-webkit-justify-content': 'flex-end !important',
      paddingRight: '1.7rem',
      minHeight: 'auto',
      transition: 'height 1s ease !important',
    },
  },
  menuIcon: {
    border: '1px solid rgba(0,0,0,.1)',
    color: '#000',
    padding: 2,
    width: '2.8rem !important',
  },
  menuLinks: {
    // fontFamily: 'Helvetica For TargetRegular',
    textDecoration: 'none',
    color: '#000',
  },
  loginBtn: {
    paddingTop: '20px',
  },
  customButton: {
    fontSize: 14,
    padding: '14px 26px',
    marginBottom: 11,
    borderRadius: 50,
    background: '#FFF',
    // color: '#fff',
    minWidth: 125,
    fontFamily: 'Helvetica',
    border: '1px solid #000',
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      background: '#d61b32',
      boxShadow: 'none',
    },
  },
}));
export default function NavMenu({ setLoginSuccess, successLogin, loggedInUser, logOut, handleNotifications }) {
  // const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openSignUp, setopenSignUp] = React.useState(false);
  // eslint-disable-next-line no-unused-vars

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDialog = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openLogin, setOpenLogin] = React.useState(false);

  const classes = useStyles();
  console.log('openDialog ', openDialog);
  return (
    <React.Fragment>
      <Login openLogin={openLogin} setOpenLogin={setOpenLogin} />
      <SignUp isheader={true} openSignUp={openSignUp} setopenSignUp={setopenSignUp} />
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title='Mobile NavBar Menu'>
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={openDialog ? 'elevate-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={openDialog ? 'true' : undefined}
          >
            <MenuIcon fontSize='large' className={classes.menuIcon} />
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        className={classes.menuContainer}
        anchorEl={anchorEl}
        id='elevate-menu'
        open={openDialog}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1.5,
            width: '100%',
            left: 0,
          },
        }}
        transitionDuration={100}
        TransitionComponent={Collapse}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        role='menu'
        //
      >
        <MenuItem role='menuitem'>
          <Link to='/' className={classes.menuLinks}>
            Home
          </Link>
        </MenuItem>
        <MenuItem role='menuitem' id='why_elevate_click'>
          <Link to='/why-elevate' className={classes.menuLinks}>
            Why Elevate
          </Link>
        </MenuItem>
        <MenuItem role='menuitem' id='agenda_click'>
          <Link to='/agenda' className={classes.menuLinks}>
            Agenda
          </Link>
        </MenuItem>
        {/* <MenuItem>
          <NavHashLink to='/#speakers' className={classes.menuLinks}>
            Speakers
          </NavHashLink>
        </MenuItem> */}
        {/* <MenuItem>
          <NavHashLink to='/agenda' className={classes.menuLinks}>
            Agenda
          </NavHashLink>
        </MenuItem> */}
        {/* <MenuItem>
          <Link to='/mentorship' className={classes.menuLinks}>
            Mentorship
          </Link>
        </MenuItem> */}
        <MenuItem role='menuitem'>
          <Link to='/highlights' className={classes.menuLinks}>
            Past Highlights
          </Link>
        </MenuItem>
        <MenuItem role='menuitem'>
          <Link to='/gallery' className={classes.menuLinks}>
            Gallery
          </Link>
        </MenuItem>
        <MenuItem role='menuitem'>
          <Link to='/faq' className={classes.menuLinks}>
            FAQs
          </Link>
        </MenuItem>
        {/* <MenuItem>
          <Link to='/faqs' className={classes.menuLinks}>
            FAQs
          </Link>
        </MenuItem> */}

        {/* {loggedInUser.id ? (
          <>
            <MenuItem>
              <span className={classes.menuLinks}>{loggedInUser.fullName}</span>
            </MenuItem>
            <MenuItem>
              <span className={classes.menuLinks} onClick={logOut}>
                Logout
              </span>
            </MenuItem>
          </>
        ) : ( */}
        <>
          {/* <MenuItem>
            <span
              className={classes.menuLinks}
              onClick={() => {
                setOpen(true);
                // setLogoutSucces(false);
              }}
            >
              Login
            </span>
          </MenuItem> */}
          <Login
            open={open}
            loggedInUser={loggedInUser}
            successLogin={successLogin}
            logoutSucess={false}
            setLoginSuccess={setLoginSuccess}
            setOpen={setOpen}
          ></Login>
          {/* <MenuItem>
            <Link to='#' className={classes.menuLinks} onClick={() => setopenSignUp(true)}>
              <span>Request an invitation</span>
            </Link>
          </MenuItem> */}
        </>

        {/* )} */}
      </Menu>
    </React.Fragment>
  );
}
