import * as React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Box, Dialog, DialogContent, DialogContentText, Slide, useMediaQuery } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  closeIconContainer: {
    //padding: '40px',
    // height: '80px',
  },
  closeIcon: {
    float: 'right',
    right: '20px',
    top: '10px',
    position: 'absolute',
    fontSize: '30px !important',
    cursor: 'pointer',
    color: '#fff !important',
    border: 'none',
    padding: '3px',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      right: '10px',
    },
  },
  speakerDetail: {
    fontSize: '15px !important',
    color: '#fff !important',
  },
  speakerName: {
    fontWeight: '600 !important',
    fontSize: (props) => {
      return props.mobile ? '32px !important' : props.tablet ? '28px !important' : props.windowsLaptop ? '37px !important' : '54px !important';
    },
    color: '#fff',
    textAlign: 'left !important',
    padding: (props) => (props.mobile ? '0 0 6px 0px' : '0px 0px 0px 0px'),
  },
  speakerNameInDialog: {
    fontWeight: '600 !important',
    fontSize: (props) => (props.mobile ? '32px !important' : '44px !important'),
    color: '#fff',
    textAlign: 'left !important',
  },
  speakerProfession: {
    fontWeight: '300 !important',
    color: '#fff ',
    textAlign: 'left !important',
    lineHeight: '1 !important',
  },
  speakerProfessionInDialog: {
    fontWeight: '300 !important',
    color: '#fff ',
    fontSize: '16px !important',
    textAlign: 'left !important',
  },
  speakerImg: {
    cursor: 'pointer',
    height: (props) => (props.mobile ? 350 : props.tablet ? 300 : props.windowsLaptop ? 350 : 550),
  },
  dialogImg: {
    margin: '0 auto',
    marginBottom: '-6px',
    padding: '18px 0 0 0',
  },
  dialogBox: {
    // width: (props) => (props.windowsLaptop ? '50%' : props.tablet ? '70%' : '100%'),
    margin: '0 auto',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& .MuiPaper-root': {
      background: '#0e141f !important',
    },
  },
  main: {
    // padding: '10% 5%',
    textAlign: 'center',
    width: '100%',
    '&:focus-visible': {
      borderRadius: 50,
      outline: '2px dotted #fff',
      outlineOffset: 2,
    },
  },
  imgBtn: {
    // height: '290px',
    '&:focus-visible': {
      opacity: 0.8,
      outline: '2px dotted #fff',
      border: '1px solid red',
    },
  },
  speakerInfo: {
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  dialogImgContainer: {
    textAlign: 'center',
  },
}));
const AboutSpeaker = ({ aboutSpeaker, thumbnail, tablet, mobile, windowsLaptop }) => {
  const classes = useStyles({ tablet, mobile, windowsLaptop });
  return (
    <div className={thumbnail ? classes.speakerInfo : null}>
      <Box sx={{ mt: 0, padding: { md: '20px 40px 0 40px', xs: '20px 0 0 0' } }}>
        <Typography variant='body2' align='left' className={classes.speakerDetail}>
          {aboutSpeaker !== undefined && aboutSpeaker.split('|')[0]}
        </Typography>
      </Box>
    </div>
  );
};
const SpeakerCard = ({ speakerDetail }) => {
  const { name, company: organisation, designation, engagement_image: speakerImg, bio: aboutSpeaker, dialog_image } = speakerDetail;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between(768, 1023));
  const isWindowsLaptop = useMediaQuery((theme) => theme.breakpoints.between(1024, 1700));
  const [mobile, setMobile] = React.useState(false);
  const [tablet, setTablet] = React.useState(false);
  const [windowsLaptop, setWindowsLaptop] = React.useState(false);

  const classes = useStyles({ tablet, mobile, windowsLaptop });
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    if (isMobile) {
      setMobile(true);
    }
    if (isTablet) {
      setTablet(true);
    }
    if (isWindowsLaptop) {
      setWindowsLaptop(true);
    }
  }, [isMobile, isTablet, isWindowsLaptop]);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box className={classes.main} sx={{ p: { xs: '0 5%', md: '10% 5%' } }}>
      <Dialog
        open={open}
        className={classes.dialogBox}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        role='dialog'
        aria-modal='true'
        aria-label={`${name} bio`}
        sx={{
          overflow: 'scroll',
          '& .MuiModal-backdrop': {
            backgroundColor: '#000000b5',
          },
        }}
      >
        <div className={classes.closeIconContainer}>
          <button className={classes.closeIcon} onClick={handleClose} aria-label='close'>
            <CloseSharpIcon onClick={handleClose} />
          </button>
        </div>
        <div className={classes.dialogImgContainer}>
          <img
            src={dialog_image}
            alt={name}
            className={classes.dialogImg}
            style={{ width: mobile ? '80%' : tablet ? '75%' : windowsLaptop ? '90%' : '70%' }}
          />
        </div>
        <DialogContent style={{ padding: '0 30px 30px 30px', overflowY: 'clip' }}>
          <DialogContentText id='alert-dialog-slide-description'>
            <AboutSpeaker
              designation={designation}
              organisation={organisation}
              aboutSpeaker={aboutSpeaker}
              classes={classes}
              thumbnail={false}
              isDialog={true}
              mobile={mobile}
              tablet={tablet}
              windowsLaptop={windowsLaptop}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          position: 'relative',
          '& :hover': {
            cursor: 'pointer',
          },
        }}
      >
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          className={classes.imgBtn}
          onClick={handleClickOpen}
          aria-label={`${name} ,${designation},${organisation}, know more`}
        >
          <img
            src={speakerImg}
            alt={` ${name}`}
            onClick={handleClickOpen}
            style={{
              width: '90%',
            }}
          />
        </button>
        <AboutSpeaker designation={designation} organisation={organisation} classes={classes} thumbnail={true} handleClickOpen={handleClickOpen} />
      </Box>
    </Box>
  );
};

export default SpeakerCard;
