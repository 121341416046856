import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

export default function SpeakerAccordion({ expanded, panelId, name, designation, bio }) {
  return (
    <div>
      <Accordion
        expanded={expanded === panelId}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          pb: 1.5,
        }}
      >
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          tabIndex={-1}
          sx={{
            p: 0,

            '& .Mui-expanded': {
              m: '0 !important',
            },
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              flexDirection: 'column',
              m: 0,
              cursor: 'default',
            },
            '&:focus-visible': {
              outline: '0px dotted #666666',
              outlineOffset: 2,
              backgroundColor: 'transparent',
            },
          }}
        >
          <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{name}</Typography>
          <Typography sx={{ fontWeight: 'bold', fontSize: 16, color: '#dddddd', mr: { md: '56px', xs: '10px', sm: '10px' }, pb: 1 }}>
            {designation}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#dddddd', mr: { md: '56px', xs: '10px', sm: '10px' } }}>{bio}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
